import React, { useEffect } from "react"

declare global {
  interface Window {
    adsbygoogle: any[]
  }
}

const MultiplexAd: React.FC<any> = ({ path }) => {
  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [path])

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-format="autorelaxed"
      data-ad-client="ca-pub-9783706503637594"
      data-ad-slot="4414743064"
    ></ins>
  )
}

export default MultiplexAd
