import React from "react"
import { graphql, Link as GL } from "gatsby"
import {
  Container,
  Heading,
  Stack,
  Image,
  Text,
  Tag,
  Box,
  OrderedList,
  ListItem,
  UnorderedList,
  Spacer,
  LinkBox,
  LinkOverlay,
  Link,
  Divider,
  TableContainer,
  Table,
  TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tfoot,
  AspectRatio,
} from "@chakra-ui/react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import Seo from "../components/seo"
import dayjs from "dayjs"
import { GoFileDirectory } from "react-icons/go"
import { BsClock, BsClockHistory, BsInstagram, BsTwitter } from "react-icons/bs"
import { IoMdQuote } from "react-icons/io"
import { useLocation } from "@reach/router"
import TableOfContents from "../components/blog/table-of-contents"
import { ShareButtonsWidget } from "../components/blog/share-buttons-widget"
import MultiplexAd from "../components/ads/multiplex-ad"
import InArticleAd from "../components/ads/in-article-ad"

const crypto = require("crypto")

const BlogDetail: React.FC<any> = ({ data, pageContext, location: loc }) => {
  const path = loc?.pathname || ""
  const location = useLocation()
  const post = data.allContentfulArticles.edges[0].node
  const instagramAccount = data.site.siteMetadata.instagramAccount
  const twitterAccount = data.site.siteMetadata.twitterAccount
  const { next, previous } = pageContext
  const url = location.href
  const title = post.title

  const options: any = {
    renderMark: {
      [MARKS.BOLD]: (text: string) => (
        <Text as={`span`} fontWeight="bold" borderBottom={`dotted 1px`}>
          {text}
        </Text>
      ),
      [MARKS.CODE]: (text: string) => {
        if (!text) return null

        return (
          <AspectRatio maxW="600px" ratio={1} maxHeight={`450px`}>
            <Box dangerouslySetInnerHTML={{ __html: text }} />
          </AspectRatio>
        )
      },
      [MARKS.UNDERLINE]: (text: string) => {
        return (
          <Box as={`span`}>
            <Text
              as={`mark`}
              bg={`linear-gradient(transparent 80%, #00B7CE 0%)`}
            >
              {text}
            </Text>
          </Box>
        )
      },
    },
    renderNode: {
      [INLINES.EMBEDDED_ENTRY]: (node: any, children: any) => {
        const targetId = node.data.target.sys.id
        const targetPost = post.content.references.filter(
          (ref: any) => ref.contentful_id === targetId
        )[0]

        return (
          <Stack
            as={LinkBox}
            h={{ base: "100%", lg: `150px` }}
            w={{ base: "100%", lg: `600px` }}
            p={4}
            bg={`blackAlpha.50`}
            direction="row"
            spacing={4}
            _hover={{
              opacity: 0.8,
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)",
            }}
            boxShadow="0px 0px 10px rgba(0, 0, 0, 0.4)"
          >
            <Image
              src={targetPost.images[0].file.url}
              h={`100%`}
              w={{ base: "50%", lg: `200px` }}
              objectFit={`cover`}
            />
            <Stack w={`100%`}>
              <LinkOverlay as={GL} to={`/properties/${targetPost.slug}`}>
                <Text color={`black`} fontWeight="bold" fontSize={16}>
                  {targetPost.propertyName}
                </Text>
              </LinkOverlay>

              <Text fontSize={14}>{targetPost.comment.comment}</Text>
              <Divider />
              <Text color={`blackAlpha.700`} bg={`blackAlpha.200`} p={1} px={4}>
                こちらもあわせて読む
              </Text>
            </Stack>
          </Stack>
        )
      },
      [INLINES.HYPERLINK]: (node: any, children: any) => {
        return (
          <Link color={`#5a746d`} href={node.data.uri} target="_blank">
            {children}
          </Link>
        )
      },
      [BLOCKS.PARAGRAPH]: (node: any, children: any) => {
        const REGEX_CONCLISION = /\[conclusion\](?<text>.+)\[\/conclusion\]/g
        const REGEX_SAIFARM = /\[sai-farm\]/g
        const keyword = children[0]

        const conclusionResult = REGEX_CONCLISION.exec(keyword)
        const saifarmResult = REGEX_SAIFARM.exec(keyword)

        if (conclusionResult) {
          const {
            groups: { text },
          }: any = conclusionResult

          return (
            <Box bg={`teal.50`} p={8} pt={12} position={`relative`}>
              <Box
                position={`absolute`}
                top={0}
                left={0}
                bg={`teal.300`}
                p={2}
                py={1}
              >
                <Text fontSize={13} color={`teal.800`}>
                  結論
                </Text>
              </Box>
              <Text fontSize={14} color={`teal.800`}>
                {text}
              </Text>
            </Box>
          )
        }

        if (saifarmResult) {
          return (
            <Link href={`/contact/`}>
              <Image
                src={`/common/saifarm-banner.png`}
                alt={`saifarm-banner`}
              />
            </Link>
          )
        }

        return (
          <Text lineHeight={2.8} fontSize={14}>
            {children}
          </Text>
        )
      },
      [BLOCKS.HEADING_1]: (node: any, children: any) => {
        return (
          <Stack spacing={4}>
            <Heading
              as={`h1`}
              lineHeight={2.2}
              fontSize={18}
              px={2}
              color={`gray.700`}
            >
              {children}
            </Heading>
            <InArticleAd path={path} />
          </Stack>
        )
      },
      [BLOCKS.HEADING_2]: (node: any, children: any) => {
        const anchor = crypto
          .createHash("md5")
          .update(node.content[0].value)
          .digest("hex")

        return (
          <Heading
            as={`h2`}
            id={anchor}
            lineHeight={2}
            fontSize={16}
            bg={`blackAlpha.50`}
            p={2}
            px={4}
            color={`gray.900`}
            borderLeft={`4px`}
          >
            {children}
          </Heading>
        )
      },
      [BLOCKS.HEADING_3]: (node: any, children: any) => {
        const anchor = crypto
          .createHash("md5")
          .update(node.content[0].value)
          .digest("hex")

        return (
          <Heading
            as={`h3`}
            id={anchor}
            lineHeight={2}
            fontSize={16}
            px={2}
            py={2}
            color={`gray.900`}
            borderBottom={`1px`}
          >
            {children}
          </Heading>
        )
      },
      [BLOCKS.HEADING_4]: (node: any, children: any) => {
        const anchor = crypto
          .createHash("md5")
          .update(node.content[0].value)
          .digest("hex")

        return (
          <Heading
            as={`h4`}
            id={anchor}
            lineHeight={2}
            fontSize={15}
            px={2}
            py={1}
            color={`gray.900`}
          >
            {children}
          </Heading>
        )
      },
      [BLOCKS.TABLE]: (node: any, children: any) => {
        const { content } = node
        const [headers, ...rows] = content

        return (
          <Table variant="simple">
            <Thead>
              <Tr>
                {headers.content.map((th: any, i: number) => {
                  return <Th key={i}>{th.content[0].content[0].value}</Th>
                })}
              </Tr>
            </Thead>
            <Tbody>
              {rows.map((row: any, i: number) => {
                return (
                  <Tr key={i}>
                    {row.content.map((td: any, i: number) => {
                      return (
                        <Td key={i} fontSize={13} lineHeight={1.8}>
                          {td.content[0].content[0].value}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        )
      },
      [BLOCKS.OL_LIST]: (node: any, children: any) => {
        return (
          <Stack p={0} position={`relative`} align="start" spacing={0}>
            <Box bg={`blackAlpha.700`} color={`white`} px={2}>
              <Text fontWeight={`normal`} fontSize={14}>
                CHECK!
              </Text>
            </Box>
            <OrderedList
              pl={8}
              spacing={2}
              bg={`blackAlpha.50`}
              py={4}
              w={`100%`}
              fontWeight={`bold`}
              fontSize={13}
            >
              {node.content.map((lt: any, index: number) => (
                <ListItem pl={2} key={index}>
                  {lt.content[0].content[0].value}
                </ListItem>
              ))}
            </OrderedList>
          </Stack>
        )
      },
      [BLOCKS.UL_LIST]: (node: any, children: any) => {
        return (
          <Stack p={0} position={`relative`} align="start" spacing={0}>
            <Box bg={`blackAlpha.700`} color={`white`} px={2}>
              <Text fontSize={14}>POINT</Text>
            </Box>
            <UnorderedList
              pl={8}
              spacing={2}
              bg={`blackAlpha.50`}
              py={4}
              w={`100%`}
              fontWeight={`bold`}
              fontSize={13}
            >
              {node.content.map((lt: any, index: number) => (
                <ListItem pl={2} key={index}>
                  {lt.content[0].content[0].value}
                </ListItem>
              ))}
            </UnorderedList>
          </Stack>
        )
      },
      [BLOCKS.QUOTE]: (node: any, children: any) => {
        return (
          <Stack bg={`blackAlpha.50`} borderLeft={`4px`} p={4}>
            <IoMdQuote />
            <Text color={`gray.600`}>{children}</Text>
          </Stack>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
        return (
          <Image
            objectFit={`cover`}
            w={node.data.target.width}
            src={node.data.target.file.url}
          />
        )
      },
    },
  }

  return (
    <Layout>
      <Seo
        title={post.title}
        description={post.metaDescription.metaDescription}
        image={post?.featuredPhoto?.file?.url}
        isArticle
      />
      <Box h={{ base: "4vh", lg: `10vh` }} backgroundSize="cover"></Box>
      <Container maxW="container.xl" as={`main`} p={0}>
        <Stack
          pb={{ base: 8, lg: 24 }}
          pt={{ base: 12, lg: 4 }}
          spacing={8}
          direction="row"
        >
          <Stack w={{ base: "100%", lg: `70%` }} spacing={4}>
            <Stack
              direction="row"
              align="center"
              p={{ base: 4, lg: 0 }}
              divider={
                <Text fontSize={36} mx={4} fontWeight="thin">
                  |
                </Text>
              }
            >
              <Stack justify="center" align="center" spacing={0}>
                <Text>{dayjs(post.createdAt).format("YYYY")}</Text>
                <Text fontSize={{ base: 20, lg: 20 }}>
                  {dayjs(post.createdAt).format("MM/DD")}
                </Text>
              </Stack>

              <Heading
                as={`h2`}
                fontSize={{ base: 16, lg: 20 }}
                color={`yellow.900`}
                lineHeight={1.8}
              >
                {post.title}
              </Heading>
            </Stack>
            <Stack
              direction="row"
              py={1}
              px={{ base: 4, lg: 0 }}
              align="center"
            >
              <GoFileDirectory size={20} />
              <Tag
                bg={`yellow.900`}
                color={`white`}
                fontSize={{ base: 10, lg: 10 }}
              >
                {post?.category?.name}
              </Tag>
              <Stack direction="row" align="center">
                <BsClock size={10} />
                <Text fontSize={{ base: 10, lg: 10 }}>
                  {dayjs(post.createdAt).format("YYYY年MM月DD日")}
                </Text>
              </Stack>
              <Stack direction="row" align="center">
                <BsClockHistory size={10} />
                <Text fontSize={{ base: 10, lg: 10 }}>
                  {dayjs(post.updatedAt).format("YYYY年MM月DD日")}
                </Text>
              </Stack>
            </Stack>
            <ShareButtonsWidget url={url} title={title} />
            {post.featuredPhoto && (
              <Image
                src={post.featuredPhoto.file.url}
                h={{ base: 56, lg: 96 }}
                pb={4}
                objectFit={{ base: `contain`, lg: "cover" }}
              />
            )}

            <Stack
              w={`100%`}
              p={4}
              display={{ base: "flex", lg: "none" }}
              spacing={8}
            >
              <Stack bg={`blackAlpha.50`}>
                <Heading
                  bg={`black`}
                  textAlign="center"
                  color="white"
                  fontSize={16}
                  py={2}
                >
                  著者
                </Heading>
                <Stack direction="row" p={4} align="center" spacing={4}>
                  <Image
                    src={post.author.avatar.file.url}
                    alt={post.author.name}
                    h={8}
                    w={8}
                    rounded={`full`}
                  />
                  <Text>{post.author.name}</Text>
                </Stack>
                <Divider />
                <Text p={4} fontSize={14} lineHeight={2}>
                  {post.author.description.description}
                </Text>
              </Stack>
              {post?.content?.raw && <TableOfContents raw={post.content.raw} />}
            </Stack>
            {post.content && (
              <Stack spacing={4} p={{ base: 4, lg: 0 }}>
                {renderRichText(post.content, options)}
              </Stack>
            )}
            <InArticleAd path={path} />
            <Box pb={12}>
              <ShareButtonsWidget url={url} title={title} />
            </Box>
            <MultiplexAd path={path} />
            <Heading as={`h3`} fontWeight={`normal`} fontSize={14} pt={12}>
              この記事が気に入った方はSNSをフォローしてください
            </Heading>
            <Stack direction={`row`}>
              <Stack w={`50%`}>
                <Link
                  bg={`teal.50`}
                  href={`https://twitter.com/${twitterAccount}`}
                  isExternal
                  p={4}
                  h={{ base: "100px", lg: "100%" }}
                >
                  <BsTwitter />
                  <Text mt={1}>Twitterをフォローする</Text>
                </Link>
              </Stack>
              <Stack w={`50%`}>
                <Link
                  bg={`blackAlpha.50`}
                  href={`https://www.instagram.com/${instagramAccount}`}
                  isExternal
                  p={4}
                  h={{ base: "100px", lg: "100%" }}
                >
                  <BsInstagram />
                  <Text mt={1}>Instagramをフォローする</Text>
                </Link>
              </Stack>
            </Stack>
            <Link href={`/contact/`}>
              <Image
                src={`/common/saifarm-banner.png`}
                alt={`saifarm-banner`}
                w={`100%`}
              />
            </Link>

            <Stack
              direction="row"
              mb={{ base: 4, lg: 8 }}
              p={{ base: 4, lg: 0 }}
              pt={12}
            >
              <Stack w={{ base: "50%", lg: `300px` }}>
                {previous && (
                  <>
                    <Text fontSize={13}>前の記事</Text>
                    <LinkBox as="article">
                      <Box
                        borderLeft={`4px`}
                        w={{ base: "100%", lg: `300px` }}
                        bg={`blackAlpha.50`}
                        p={4}
                        _hover={{ bg: "blackAlpha.100" }}
                      >
                        <LinkOverlay as={GL} to={`/articles/${previous.slug}`}>
                          <Text fontSize={{ base: 12, lg: 14 }}>
                            {previous.title}
                          </Text>
                        </LinkOverlay>
                      </Box>
                    </LinkBox>
                  </>
                )}
              </Stack>

              <Spacer />
              <Stack align="end" w={{ base: "50%", lg: `300px` }}>
                {next && (
                  <>
                    <Text fontSize={13}>次の記事</Text>
                    <LinkBox as="article">
                      <Box
                        w={{ base: "100%", lg: `300px` }}
                        bg={`blackAlpha.50`}
                        p={4}
                        borderRight={`4px`}
                        _hover={{ bg: "blackAlpha.100" }}
                      >
                        <LinkOverlay as={GL} to={`/articles/${next.slug}`}>
                          <Text fontSize={{ base: 12, lg: 14 }}>
                            {next.title}
                          </Text>
                        </LinkOverlay>
                      </Box>
                    </LinkBox>
                  </>
                )}
              </Stack>
            </Stack>
          </Stack>
          <Stack
            w={`30%`}
            h={`800px`}
            display={{ base: "none", lg: "flex" }}
            sx={{
              position: "sticky",
              top: "0",
            }}
          >
            <Stack bg={`blackAlpha.50`}>
              <Heading
                bg={`black`}
                textAlign="center"
                color="white"
                fontSize={16}
                py={2}
              >
                著者
              </Heading>
              <Stack direction="row" p={4} align="center" spacing={4}>
                <Image
                  src={post.author.avatar.file.url}
                  alt={post.author.name}
                  h={8}
                  w={8}
                  rounded={`full`}
                />
                <Text fontSize={14}>{post.author.name}</Text>
              </Stack>
              <Divider />
              <Text p={4} fontSize={13} lineHeight={2}>
                {post.author.description.description}
              </Text>
            </Stack>
            {post?.content?.raw && <TableOfContents raw={post.content.raw} />}
          </Stack>
        </Stack>
      </Container>
    </Layout>
  )
}

export default BlogDetail

export const query = graphql`
  query ($slug: String!) {
    site {
      siteMetadata {
        twitterAccount
        instagramAccount
      }
    }
    allContentfulArticles(
      filter: { slug: { eq: $slug } }
      sort: { order: DESC, fields: createdAt }
    ) {
      edges {
        node {
          author {
            avatar {
              file {
                url
              }
            }
            name
            description {
              description
            }
          }
          category {
            name
          }
          content {
            raw
            references {
              # ... on ContentfulProperties {
              #   contentful_id
              #   images {
              #     file {
              #       url
              #     }
              #   }
              #   comment {
              #     comment
              #   }
              #   slug
              #   propertyName
              #   sys {
              #     contentType {
              #       sys {
              #         id
              #       }
              #     }
              #   }
              # }
              ... on ContentfulAsset {
                id
                file {
                  url
                }
                height
                width
                contentful_id
                __typename
              }
            }
          }
          createdAt
          featuredPhoto {
            file {
              url
            }
          }
          tags {
            name
          }
          title
          metaDescription {
            metaDescription
          }
          slug
          updatedAt
        }
      }
    }
  }
`
