import React from "react"
import { Stack, Text, Box } from "@chakra-ui/react"

import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  LineShareButton,
  LineIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share"

export const ShareButtonsWidget: React.FC<{ url: string; title: string }> = ({
  url,
  title,
}) => {
  return (
    <Stack
      direction="row"
      spacing={4}
      bg={`blackAlpha.50`}
      p={4}
      pt={10}
      position={`relative`}
    >
      <Box position={`absolute`} top={0} left={0} bg={`gray.200`} py={1} px={2}>
        <Text fontSize={13}>SNSでシェアする</Text>
      </Box>
      <FacebookShareButton url={url} title={title}>
        <FacebookIcon size={25} round />
      </FacebookShareButton>
      <TwitterShareButton url={url} title={title}>
        <TwitterIcon size={25} round />
      </TwitterShareButton>
      <LineShareButton url={url} title={title}>
        <LineIcon size={25} round />
      </LineShareButton>
      <EmailShareButton url={url} title={title}>
        <EmailIcon size={25} round />
      </EmailShareButton>
    </Stack>
  )
}
